






















































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { organisationModule } from "@/store/modules/OrganisationModule";

@Component
export default class Invoice extends Vue {
  public invoice: any = null;

  public async activated() {
    this.invoice = await adminService.getInvoiceDetails(
      organisationModule.organisation.id,
      this.$route.params.id
    );

  }

  public async download(type: string) {
    var result = await adminService.getInvoiceUrl(organisationModule.organisation.id, this.$route.params.id, type);
    location.href  = result.url;
  }
}
